import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import Store from '@/store/';
import customAxios from '@/plugins/custom-axios';
import qs from 'qs';

import Home from '@/pages/views/Home.vue';
import AppPortals from '@/pages/views/AppPortals.vue';
import UnexpectedError from '@/pages/views/UnexpectedError.vue';
import NotLogined from '@/pages/views/NotLogined.vue';
import VueGtag from 'vue-gtag';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		// eslint-disable-next-line no-unused-vars
		redirect: (to) => {
			const { url } = to.query;
			window.location.href = url ? `/login/?${qs.stringify({ url })}` : '/login/';
		}
	},
	{
		path: '/error',
		component: UnexpectedError
	},
	{
		path: '/notlogin',
		component: NotLogined
	},
	{
		path: '/',
		name: 'home',
		meta: { requiresAuth: true },
		component: Home,
		children: [
			{
				path: '/',
				component: AppPortals
			},
			{
				path: '*',
				beforeEnter: (to, from, next) => {
					next('/');
				}
			}
		]
	}
];

const isProduction = () => {
	const { port, protocol, hostname } = window.location;

	if (port) return false;
	if (protocol === 'https:') {
		if (hostname.startsWith('dev.') || hostname.indexOf('.dev.') !== -1) return false;
		if (hostname.startsWith('sandbox.') || hostname.indexOf('.sandbox.') !== -1) return false;
		if (hostname.startsWith('green.')) return false;
	}
	return true;
};

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		try {
			const {
				data: { token: accountToken, env }
			} = await axios.get(`/login/token`);

			Store.dispatch('setAccountToken', accountToken);
			Store.dispatch('setEnv', env);
			Vue.prototype.$axios = customAxios(accountToken);
		} catch (e) {
			next({ path: '/login', query: { url: to.path } });
		}
	}
	next();
});

Vue.use(
	VueGtag,
	{
		config: {
			id: isProduction() ? process.env.VUE_APP_GTAG_PRODUCTION : process.env.VUE_APP_GTAG_SANDBOX
		}
	},
	router
);

export default router;
