<template>
	<v-container>
		<v-row justify="center">
			<v-col cols="8" class="pa-10">
				<v-text-field
					v-model="search.text"
					v-on:keydown.enter="searchApp"
					@click:clear="clearSearchText"
					class="mt-1"
					dense
					hide-details
					flat
					solo
					clearable
					outlined
					:label="this.$t(`main.search`)"
				>
					<template v-slot:append>
						<v-btn @click="searchApp" icon><v-icon> mdi-magnify </v-icon></v-btn>
					</template>
				</v-text-field>
			</v-col>
		</v-row>
		<v-divider />
		<v-row>
			<v-col
				cols="6"
				md="4"
				lg="2"
				xl="2"
				class="pt-12 px-2"
				v-for="application in displayApplications"
				:key="application.label"
			>
				<v-tooltip
					bottom
					:max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '45%' : '30%'"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-card
							hover
							ripple
							:href="application.url"
							target="_blank"
							:aspect-ratio="1"
							width="95%"
							class="mx-auto"
							v-on="on"
							v-bind="attrs"
							:disabled="isDisabled(application.label)"
						>
							<div class="d-flex justify-center pa-6">
								<v-img
									:src="application.logoUrl"
									alt="application icon"
									contain
									:aspect-ratio="8 / 5"
								/>
							</div>
							<v-divider />
							<v-card-subtitle class="d-flex justify-center text-center ma-0 pa-1">
								{{ $t(`main.card.name.${application.label}`) }}
							</v-card-subtitle>
						</v-card>
					</template>
					<span>
						{{ $t(`main.card.explain.${application.label}`) }}
					</span>
				</v-tooltip>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import SHIFTLogo from '@/assets/shift_logo.svg';
import organizationLogo from '@/assets/domain.svg';
import axios from 'axios';

export default {
	name: 'AppPortals',
	data: () => ({
		allApplications: [],
		errorLabel: '',
		isNotPrivileged: false,
		search: { text: '', filterText: '' }
	}),
	computed: {
		isDisabled() {
			return (appName) => appName === 'organization_manager' && this.isNotPrivileged;
		},
		displayApplications() {
			return this.allApplications.filter((application) =>
				this.$t(`main.card.name.${application.label}`)
					.toLowerCase()
					.includes(this.search.filterText.toLowerCase())
			);
		}
	},
	async created() {
		await this.fetchApplications();
		await this.confirmPrivilege();
	},
	methods: {
		async fetchApplications() {
			try {
				const {
					data: { applications }
				} = await axios.get(`api/v1/applications`);
				this.allApplications = applications.map((origin) => {
					const application = origin;
					if (
						application.label === 'account_manager' ||
						application.label === 'organization_manager'
					)
						application.logoUrl = SHIFTLogo;
					if (!application.logoUrl) application.logoUrl = organizationLogo;
					return application;
				});
			} catch (e) {
				this.errorLabel = 'error_unknown';
			}
		},
		async confirmPrivilege() {
			try {
				const { data: user } = await this.$axios.oidc.get(`/api/v1/user`);
				const {
					data: { organizations }
				} = await this.$axios.oidc.get(`/api/v1/organizations?only_privileged=true`);

				this.isNotPrivileged = !organizations.length && user.accountType !== 'business';
			} catch (e) {
				this.$router.push({ path: '/error' });
			}
		},
		searchApp() {
			this.search.filterText = this.search.text;
		},
		clearSearchText() {
			this.search.filterText = '';
		}
	}
};
</script>
