import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		accountToken: null,
		env: 'env-production'
	},
	getters: {
		env: (state) => state.env
	},
	mutations: {
		setAccountToken(state, accountToken) {
			state.accountToken = accountToken;
		},
		setEnv(state, env) {
			state.env = env;
		}
	},
	actions: {
		setAccountToken({ commit }, accountToken) {
			commit('setAccountToken', accountToken);
		},
		setEnv({ commit }, env) {
			commit('setEnv', env);
		}
	},
	plugins: [
		createPersistedState({
			key: 'session',
			storage: {
				getItem: (key) => {
					const accountToken = Cookies.get(key);
					if (!accountToken) return undefined;
					return JSON.stringify({ accountToken });
				},
				setItem: (key, value) => {
					const data = JSON.parse(value);
					const params = { secure: false };
					Cookies.set(key, data.accountToken, params);
				},
				removeItem: (key) => Cookies.remove(key)
			}
		})
	]
});

export default store;
