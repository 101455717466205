<template>
	<div id="navigation">
		<v-app-bar app color="white" dark clipped-left :class="[environment]">
			<div class="d-flex align-center">
				<v-img
					:src="require('@/assets/logo_head.svg')"
					class="my-3"
					contain
					height="40"
					:width="imgWidth"
				/>
			</div>
			<v-toolbar-title class="grey--text text--darken-2 d-none d-sm-block">
				{{ $t('app.title') }}
			</v-toolbar-title>

			<v-spacer />

			<div v-if="!isMobile">
				<v-menu bottom left transition="slide-y-transition" offset-y>
					<template v-slot:activator="menuActivator">
						<v-tooltip bottom>
							<template v-slot:activator="tooltipActivator">
								<v-btn
									icon
									v-bind="menuActivator.attrs"
									v-on="{ ...menuActivator.on, ...tooltipActivator.on }"
									:max-width="24"
									class="mx-2"
								>
									<v-icon>mdi-dark mdi-earth</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('app.language') }}</span>
						</v-tooltip>
					</template>

					<v-list>
						<v-subheader>{{ $t('app.language') }}</v-subheader>
						<v-list-item-group>
							<v-list-item v-for="(language, i) in languages" :key="i">
								<v-list-item-content @click="changeLocale(language.locale)">
									<v-list-item-title>{{ language.label }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
			</div>
			<v-menu v-if="!disabledReader" bottom left transition="slide-y-transition" offset-y>
				<template v-slot:activator="menuActivator">
					<v-tooltip bottom>
						<template v-slot:activator="tooltipActivator">
							<v-btn
								icon
								v-bind="menuActivator.attrs"
								v-on="{ ...menuActivator.on, ...tooltipActivator.on }"
								:max-width="24"
								class="mx-2"
							>
								<v-avatar v-if="!!picture" size="24">
									<v-img :src="picture" alt="user picture" contain />
								</v-avatar>
								<v-avatar v-else>
									<v-icon>mdi-dark mdi-account-circle</v-icon>
								</v-avatar>
							</v-btn>
						</template>
						<span>{{ $t('app.account_info') }}</span>
					</v-tooltip>
				</template>
				<v-list width="300">
					<v-list-item>
						<v-list-item-icon>
							<v-img
								v-if="!!picture"
								:src="picture"
								alt="user picture"
								max-width="60"
								class="rounded-circle"
								contain
							/>
							<v-icon v-else size="60">mdi-dark mdi-account-circle</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title class="text-h5">{{ name }}</v-list-item-title>
							<v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider />
					<div v-if="isMobile">
						<v-list-item @click.stop="selectLanguage = !selectLanguage">
							<v-list-item-icon>
								<v-icon>mdi-dark mdi-earth</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t('app.language') }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</div>
					<v-list-item @click.stop="logout">
						<v-list-item-icon>
							<v-icon>mdi-logout-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ $t('point_reader.logout.menu') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-navigation-drawer
			v-if="!disabledMenu"
			v-model="selectLanguage"
			color="white"
			app
			bottom
			right
			temporary
		>
			<v-list>
				<v-subheader>{{ $t('app.language') }}</v-subheader>
				<v-list-item-group>
					<v-list-item v-for="(language, i) in languages" :key="i">
						<v-list-item-content @click="changeLocale(language.locale)">
							<v-list-item-title>{{ language.label }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
import axios from 'axios';

export default {
	props: {
		disabledMenu: {
			type: Boolean,
			default: false,
			required: false
		},
		disabledReader: {
			type: Boolean,
			default: false,
			required: false
		},
		name: {
			type: String,
			default: '',
			required: true
		},
		email: {
			type: String,
			default: '',
			required: true
		},
		picture: {
			type: String,
			default: null,
			required: false
		}
	},
	data: () => ({
		languages: [
			{
				locale: 'ja',
				label: '日本語'
			},
			{
				locale: 'en',
				label: 'English'
			}
		],
		selectLanguage: false
	}),
	computed: {
		environment() {
			return this.$store.getters.env;
		},
		isMobile() {
			return this.$vuetify.breakpoint.xs;
		},
		imgWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					return 180;
				default:
					return 220;
			}
		}
	},
	methods: {
		changeLocale(locale) {
			this.$i18n.locale = locale;
			this.$vuetify.lang.current = locale;
			switch (locale) {
				case 'en':
					document.getElementsByTagName('html').item(0).lang = 'en';
					break;
				default:
					this.$i18n.locale = 'ja';
					this.$vuetify.lang.current = 'ja';
					document.getElementsByTagName('html').item(0).lang = 'ja';
					break;
			}
			this.selectLanguage = false;
		},
		async logout() {
			const {
				data: { logoutUrl }
			} = await axios.get(`/logout/?ui_locales=${this.$i18n.locale}`);

			window.open(logoutUrl, '_self');
		}
	}
};
</script>
<style lang="sass" scoped>
#navigation::v-deep
	.list-item
		border-left: solid 3px transparent
		&.v-list-item--active
			border-left: solid 3px #D70027
	.red_list .v-list-item-group .v-list-item--active
		background-color: red
		color: white

.env-template
	&::after
		text-align: center
		position: absolute
		border-radius: 0 0 5px 5px
		height: 16px
		left: calc(50% - 50px)
		color: white
		font-size: 6px
		width: 100px

header.env-local
		border-bottom: 3px solid rgba(64, 64, 64, 0.8) !important
		&::after
			@extend .env-template
			content: 'local'
			background-color:  rgba(64, 64, 64, 0.8)
header.env-sandbox
		border-bottom: 3px solid rgba(255, 64, 64, 0.8) !important
		&::after
			@extend .env-template
			content: 'sandbox'
			background-color:  rgba(255, 64, 64, 0.8)
</style>
