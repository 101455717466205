<template>
	<v-app>
		<NavigationHeader :name="user.name" :email="user.email" :picture="user.picture" />
		<v-main class="grey lighten-5">
			<router-view @updated="userPros" />
		</v-main>
		<NavigationFooter />
	</v-app>
</template>

<script>
import NavigationHeader from '@/components/NavigationHeader.vue';
import NavigationFooter from '@/components/NavigationFooter.vue';

export default {
	name: 'HomeView',
	components: {
		NavigationHeader,
		NavigationFooter
	},
	data: () => ({
		user: { name: '', picture: null }
	}),
	async created() {
		await this.userPros();
	},
	methods: {
		async userPros() {
			try {
				const { data: user } = await this.$axios.oidc.get(`/api/v1/user`);
				this.user = user;
				this.setInitLocale(user.locale);
			} catch (e) {
				this.$router.push({ path: '/error' });
			}
		},
		setInitLocale(locale) {
			this.$i18n.locale = locale;
			this.$vuetify.lang.current = locale;
			switch (locale) {
				case 'en':
					document.getElementsByTagName('html').item(0).lang = 'en';
					break;
				default:
					document.getElementsByTagName('html').item(0).lang = 'ja';
					break;
			}
		}
	}
};
</script>
